import React from 'react'

const TopBackground = () => {
  return (
    <picture className="top-main-bg">
      <source type="image/webp" srcSet="img/top-bg2.webp" />
      <img alt="" src="img/top-bg2.png" />
    </picture>
  )
}

export default TopBackground