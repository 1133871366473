import React from 'react'

const Why = () => {
  return (
    <section className="why">
      <h2 className="why__title">
        why girand?
        <span className="decor-title">why girand?</span>
      </h2>

      <ul className="why__list">
        <li className="why__item item2">
          <p>High budget game with <span> Unique 3D Metaverse. </span></p>
        </li>
        <li className="why__item item3">
          <p>Game mechanics allow you to simulate events from <span>real life</span> in the game.</p>
        </li>
        <li className="why__item item4">
          <p>Interest in the <span>game prevails</span> over earnings.</p>
        </li>
        <li className="why__item item5">
          <p>Main income from <span>NFT mining.</span></p>
        </li>
        <li className="why__item item6">
          <p>The system of taxation and budgeting, as in a <span>real state.</span></p>
        </li>
        <li className="why__item item7">
          <p><span>A unique mechanic</span> that limits the drop in NFT value.</p>
        </li>
        <li className="why__item item8">
          <p><span>Service market</span> in the game.</p>
        </li>
        <i></i><i></i>
      </ul>
      <div className="bubbles">
        <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
      </div>
    </section>
  )
}

export default Why