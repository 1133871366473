import React from 'react'

const Rings = () => {
  return (
    <section className="rings">
      <h2 className="rings__title">NFT Rings</h2>
      <p className="rings__und-title">
        NFT rings - ancient artifacts of Girand. Each ring is unique and has very strong abilities, which further enhances your Hero. After the disaster, many of them were lost or stolen by the minions of the lord of the ocean.
      </p>
      <div className="rings__wrapper">
        <div className="rings__run">
          <picture className="rings__pic-1">
            <img src="img/ring1.png" alt="" />
          </picture>
          <picture className="rings__pic-2">
            <img src="img/ring2.png" alt="" />
          </picture>
          <picture className="rings__pic-3">
            <img src="img/ring3.png" alt="" />
          </picture>
          <picture className="rings__pic-4">
            <img src="img/ring4.png" alt="" />
          </picture>
          <picture className="rings__pic-5">
            <img src="img/ring5.png" alt="" />
          </picture>
        </div>
        <div className="rings__run">
          <picture className="rings__pic-1">
            <img src="img/ring1.png" alt="" />
          </picture>
          <picture className="rings__pic-2">
            <img src="img/ring2.png" alt="" />
          </picture>
          <picture className="rings__pic-3">
            <img src="img/ring3.png" alt="" />
          </picture>
          <picture className="rings__pic-4">
            <img src="img/ring4.png" alt="" />
          </picture>
          <picture className="rings__pic-5">
            <img src="img/ring5.png" alt="" />
          </picture>
        </div>
      </div>
    </section>
  )
}

export default Rings