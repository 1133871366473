function Gameplay() {
  return (
    <section className="gp">
      <picture className="gp__pic">
        <img src="img/ktumo.png" alt="" />
      </picture>
      <div className="gp__content">
        <h2>Core gameplay</h2>
        <p>Cooperative online arena where players must battle waves of monsters, defending the kingdom. Develop your heroes and guardians to defeat the Ancient Evil.</p>
        <p>The main mechanics are implemented in the Hero Defense model.</p>
      </div>
    </section>
  )
}

export default Gameplay;