import { Outerlinks } from "../../../consts";

function JoinCommunity() {
  return (

    <div className="join">
      <ul className="join__list">
        <li className="join__item">
          <a href={Outerlinks.TelegramEn} target='_blank' rel='noreferrer' className="join__link">
            <div className="join__icon-container">
              <svg className="correct correct-tg" width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.56073 14.9096C8.37711 14.7242 7.73036 13.5954 7.12352 12.401C6.51666 11.2066 5.89722 10.0537 5.74697 9.83897C5.5757 9.59426 4.60693 9.01237 3.15019 8.2792C0.529585 6.96028 0.177437 6.67728 0.248343 5.94713C0.313131 5.27999 0.578772 5.14196 4.26778 3.85861C5.9551 3.27162 8.67668 2.32507 10.3157 1.75517C11.9548 1.18527 13.4776 0.718994 13.6998 0.718994C14.1636 0.718994 14.5994 1.12903 14.5994 1.56544C14.5994 1.72972 14.0527 3.44301 13.3845 5.37276C12.7163 7.30251 11.9493 9.52165 11.6801 10.3042C10.7701 12.9494 10.0743 14.7211 9.84191 14.9845C9.52325 15.3456 8.96007 15.3127 8.56073 14.9096Z" />
              </svg>
            </div>
          </a>
        </li>

        {/* <li className="join__item">
          <a href={Outerlinks.TelegramRu} target='_blank' rel='noreferrer' className="join__link">
            <div className="join__icon-container">
              <svg className="correct" width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.56073 14.9096C8.37711 14.7242 7.73036 13.5954 7.12352 12.401C6.51666 11.2066 5.89722 10.0537 5.74697 9.83897C5.5757 9.59426 4.60693 9.01237 3.15019 8.2792C0.529585 6.96028 0.177437 6.67728 0.248343 5.94713C0.313131 5.27999 0.578772 5.14196 4.26778 3.85861C5.9551 3.27162 8.67668 2.32507 10.3157 1.75517C11.9548 1.18527 13.4776 0.718994 13.6998 0.718994C14.1636 0.718994 14.5994 1.12903 14.5994 1.56544C14.5994 1.72972 14.0527 3.44301 13.3845 5.37276C12.7163 7.30251 11.9493 9.52165 11.6801 10.3042C10.7701 12.9494 10.0743 14.7211 9.84191 14.9845C9.52325 15.3456 8.96007 15.3127 8.56073 14.9096Z" />
              </svg>
            </div>
          </a>
        </li> */}

        <li className="join__item">
          <a href={Outerlinks.Instagram} target='_blank' rel='noreferrer' className="join__link">
            <div className="join__icon-container">
              <svg className="correct" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff">
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
              </svg>
            </div>
          </a>
        </li>

        <li className="join__item">
          <a href={Outerlinks.Youtube} target='_blank' rel='noreferrer' className="join__link">
            <div className="join__icon-container">
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_988_15)">
                <path fillRule="evenodd" clipRule="evenodd" d="M25.74 7.76426C25.74 7.76426 25.4857 5.9599 24.7065 5.16548C23.7177 4.12223 22.6094 4.11736 22.1016 4.05683C18.4633 3.79175 13.0057 3.79175 13.0057 3.79175H12.9943C12.9943 3.79175 7.53675 3.79175 3.89837 4.05683C3.38975 4.11736 2.28231 4.12223 1.29269 5.16548C0.5135 5.96011 0.26 7.76426 0.26 7.76426C0.26 7.76426 0 9.88367 0 12.0021V13.9888C0 16.108 0.26 18.2266 0.26 18.2266C0.26 18.2266 0.5135 20.031 1.29269 20.8254C2.28231 21.8687 3.5815 21.836 4.16 21.9448C6.24 22.1461 13 22.2083 13 22.2083C13 22.2083 18.4633 22.2002 22.1016 21.9351C22.6094 21.8737 23.7177 21.8689 24.7065 20.8256C25.4857 20.031 25.74 18.2268 25.74 18.2268C25.74 18.2268 26 16.1082 26 13.9888V12.0021C26 9.88367 25.74 7.76426 25.74 7.76426ZM10.3155 16.3963L10.3147 9.03928L17.3396 12.7305L10.3155 16.3963Z" />
                </g>
                <defs>
                <clipPath id="clip0_988_15">
                <rect width="26" height="26" />
                </clipPath>
                </defs>
              </svg>
            </div>
          </a>
        </li>

        <li className="join__item">
          <a href={Outerlinks.Twitter} target='_blank' rel='noreferrer' className="join__link">
            <div className="join__icon-container">
              <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.4128 2.21727C19.6843 2.54031 18.9006 2.7588 18.0788 2.85638C18.9179 2.35364 19.5618 1.55816 19.8658 0.608745C19.0806 1.07451 18.2106 1.4124 17.2854 1.59453C16.5442 0.805114 15.4881 0.311768 14.3187 0.311768C12.075 0.311768 10.2552 2.13151 10.2552 4.3752C10.2552 4.69369 10.2913 5.0037 10.361 5.30129C6.98394 5.13189 3.98962 3.51427 1.98533 1.05512C1.63563 1.65513 1.43501 2.35364 1.43501 3.0982C1.43501 4.50763 2.15291 5.7519 3.24263 6.48041C2.57686 6.4595 1.94988 6.27676 1.40229 5.97161C1.40198 5.98888 1.40198 6.00615 1.40198 6.02312C1.40198 7.99196 2.80323 9.63412 4.66176 10.0072C4.32115 10.1005 3.96144 10.1499 3.59143 10.1499C3.329 10.1499 3.07475 10.1247 2.82687 10.0775C3.34385 11.6914 4.84419 12.8663 6.62272 12.8994C5.23178 13.9894 3.47991 14.6388 1.57562 14.6388C1.24834 14.6388 0.924092 14.6197 0.606812 14.5818C2.40413 15.7352 4.54055 16.4076 6.83515 16.4076C14.3093 16.4076 18.3966 10.216 18.3966 4.84582C18.3966 4.66975 18.3927 4.4943 18.3848 4.32035C19.1794 3.74761 19.8682 3.03214 20.4128 2.21727Z" />
              </svg>
            </div>
          </a>
        </li>

        {/* <li className="join__item">
          <a href={Outerlinks.Discord} target='_blank' rel='noreferrer' className="join__link">
            <div className="join__icon-container">
              <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3802 2.49779C18.3802 2.49779 16.2763 0.852011 13.7922 0.662537L13.5674 1.11003C15.8146 1.66013 16.8439 2.44799 17.9215 3.41541C16.0651 2.46826 14.2328 1.58016 11.0393 1.58016C7.84572 1.58016 6.01195 2.46826 4.15726 3.41541C5.23183 2.44799 6.4588 1.57348 8.50987 1.11003L8.28655 0.662537C5.67847 0.909349 3.69855 2.49779 3.69855 2.49779C3.69855 2.49779 1.3485 5.90423 0.945618 12.5915C3.31355 15.323 6.90997 15.3443 6.90997 15.3443L7.66163 14.3422C6.38487 13.8984 4.94341 13.1047 3.69833 11.674C5.18333 12.7969 7.42603 13.968 11.0393 13.968C14.6525 13.968 16.8935 12.7984 18.3802 11.674C17.1336 13.1047 15.6921 13.8984 14.4169 14.3422L15.1685 15.3443C15.1685 15.3443 18.7635 15.323 21.1329 12.5915C20.7285 5.90423 18.3802 2.49779 18.3802 2.49779ZM8.05697 10.756C7.16951 10.756 6.45126 9.93493 6.45126 8.92074C6.45126 7.9074 7.16972 7.0857 8.05697 7.0857C8.94444 7.0857 9.6629 7.9074 9.6629 8.92074C9.6629 9.93515 8.94444 10.756 8.05697 10.756ZM14.0215 10.756C13.1341 10.756 12.4156 9.93493 12.4156 8.92074C12.4156 7.9074 13.1341 7.0857 14.0215 7.0857C14.9088 7.0857 15.6273 7.9074 15.6273 8.92074C15.6273 9.93515 14.9073 10.756 14.0215 10.756Z" />
              </svg>
            </div>
          </a>
        </li> */}
      </ul>
    </div>

  )
}

export default JoinCommunity;