export const enum Outerlinks {
  Gitbook = 'https://gitbook.inlostcity.com/',
  Litepapper = 'litepaper.pdf',
  TelegramEn = 'https://t.me/girand_official_en',
  TelegramRu = 'https://t.me/girand_lost_city',
  Instagram = 'https://instagram.com/girand_official',
  Youtube = 'https://www.youtube.com/channel/UCEbbCuKdcCzTzMoHGoMxNgw',
  Twitter = 'https://twitter.com/GirandOfficial',
  Discord = 'https://discord.gg/jZCk8Y67tJ',
  GirandPortalGame = 'https://t.me/Girand_official_bot',
}